const answers = [
  'acura',
  'adler',
  'aston',
  'aurus',
  'bajaj',
  'buick',
  'chana',
  'chery',
  'dacia',
  'dodge',
  'eagle',
  'foton',
  'geely',
  'gonow',
  'great',
  'hafei',
  'haima',
  'haval',
  'honda',
  'horch',
  'isuzu',
  'iveco',
  'lexus',
  'lifan',
  'lotus',
  'mazda',
  'monte',
  'noble',
  'panoz',
  'qoros',
  'qvale',
  'ravon',
  'rimac',
  'rover',
  'saipa',
  'scion',
  'sears',
  'simca',
  'skoda',
  'smart',
  'steyr',
  'tagaz',
  'tatra',
  'tesla',
  'tofas',
  'volvo',
  'yulon',
  'zibar',
  'zotye',
  'aceca',
  'aerio',
  'agila',
  'agile',
  'albea',
  'alero',
  'allex',
  'altea',
  'altis',
  'amica',
  'amigo',
  'appia',
  'aprio',
  'arena',
  'aries',
  'arona',
  'arosa',
  'ascot',
  'aspen',
  'astra',
  'astro',
  'ateca',
  'atlas',
  'atrai',
  'auris',
  'axela',
  'axiom',
  'azera',
  'aztek',
  'azure',
  'beast',
  'belta',
  'benni',
  'bigua',
  'blade',
  'bongo',
  'bonus',
  'brava',
  'bravo',
  'breez',
  'brera',
  'brisa',
  'caddy',
  'campo',
  'camry',
  'capri',
  'carol',
  'carry',
  'celta',
  'ceria',
  'cervo',
  'citan',
  'civic',
  'click',
  'cobra',
  'combo',
  'copen',
  'corda',
  'corsa',
  'coupa',
  'coupe',
  'cowry',
  'creta',
  'croma',
  'crown',
  'cruze',
  'cuore',
  'cynos',
  'damas',
  'dedra',
  'delta',
  'demio',
  'derby',
  'dingo',
  'doblo',
  'dogan',
  'efini',
  'eight',
  'elise',
  'elite',
  'enjoy',
  'envoy',
  'epica',
  'equus',
  'etios',
  'etude',
  'eunos',
  'every',
  'evora',
  'excel',
  'exiga',
  'exige',
  'exora',
  'extol',
  'fabia',
  'fargo',
  'fifth',
  'flair',
  'flyer',
  'focus',
  'forte',
  'forza',
  'freda',
  'freed',
  'fuego',
  'galue',
  'gamma',
  'ghost',
  'grace',
  'grand',
  'guara',
  'gypsy',
  'haise',
  'hansa',
  'hatch',
  'hiace',
  'hijet',
  'hilux',
  'hover',
  'hyena',
  'ibiza',
  'ideal',
  'ignis',
  'iltis',
  'indis',
  'ioniq',
  'ipsum',
  'ixion',
  'jetta',
  'jimmy',
  'jimny',
  'joice',
  'juara',
  'jumpy',
  'justy',
  'kalos',
  'kamiq',
  'kappa',
  'karma',
  'karoq',
  'karry',
  'kicks',
  'knyaz',
  'kyron',
  'landy',
  'lanos',
  'largo',
  'laser',
  'latio',
  'leeza',
  'leone',
  'liana',
  'linea',
  'logan',
  'lotze',
  'lucra',
  'lybra',
  'macan',
  'manta',
  'maple',
  'march',
  'marea',
  'matiz',
  'metro',
  'micra',
  'milan',
  'mille',
  'miura',
  'model',
  'modus',
  'mokka',
  'monza',
  'murat',
  'musso',
  'myway',
  'nadia',
  'naked',
  'nexia',
  'nippa',
  'nitro',
  'nomad',
  'omega',
  'optra',
  'orion',
  'otaka',
  'oting',
  'palio',
  'panda',
  'paseo',
  'passo',
  'pilot',
  'pixis',
  'platz',
  'porte',
  'preve',
  'pride',
  'prius',
  'prizm',
  'probe',
  'pulse',
  'punto',
  'putra',
  'quest',
  'racer',
  'raize',
  'rally',
  'range',
  'rapid',
  'regal',
  'relay',
  'rezzo',
  'ritmo',
  'rocky',
  'rodeo',
  'rogue',
  'ronda',
  'rondo',
  'roomy',
  'rukus',
  'runna',
  'ryugi',
  'saber',
  'sable',
  'sahin',
  'sahra',
  'saina',
  'santa',
  'sarir',
  'savvy',
  'scala',
  'scrum',
  'scudo',
  'senat',
  'senna',
  'serce',
  'seven',
  'shuma',
  'siber',
  'siena',
  'sigma',
  'simbo',
  'sixty',
  'skala',
  'small',
  'smily',
  'sobol',
  'solio',
  'sonic',
  'soren',
  'space',
  'spade',
  'spark',
  'sport',
  'stilo',
  'storm',
  'sunny',
  'super',
  'supra',
  'suran',
  'sweet',
  'swift',
  'tager',
  'tahoe',
  'talon',
  'tanto',
  'teana',
  'tempo',
  'terra',
  'thema',
  'tiara',
  'tiggo',
  'tigra',
  'tiida',
  'tingo',
  'titan',
  'today',
  'topaz',
  'topic',
  'toppo',
  'tosca',
  'track',
  'trans',
  'turbo',
  'twizy',
  'urban',
  'urvan',
  'vamos',
  'vaneo',
  'velox',
  'venga',
  'vento',
  'venza',
  'verna',
  'versa',
  'verso',
  'vesta',
  'vezel',
  'viano',
  'vigor',
  'viper',
  'vista',
  'visto',
  'vivio',
  'volga',
  'voltz',
  'wagon',
  'xceed',
  'xedos',
  'xenia',
  'xenon',
  'xsara',
  'yaris',
  'yukon',
  'zelas',
  'zonda',
  'combi',
  'first',
  'latte',
  'serie',
  'third',
  'ardeo',
  'avant',
  'black',
  'board',
  'break',
  'cargo',
  'cedia',
  'cielo',
  'ciera',
  'cross',
  'cupra',
  'emina',
  'fiore',
  'kombi',
  'metal',
  'mixto',
  'nismo',
  'panel',
  'prime',
  'rider',
  'rocks',
  'royal',
  'samba',
  'scout',
  'sedan',
  'slide',
  'spada',
  'speed',
  'spike',
  'targa',
  'targa',
  'tepee',
  'tsiii',
  'carlo',
  'dzips',
  'hower',
  'rides',
  'romeo',
  'alpha',
  'carib',
  'carlo',
  'ceres',
  'conte',
  'cosmo',
  'cypha',
  'drive',
  'filly',
  'koral',
  'lapin',
  'levin',
  'pinin',
  'prado',
  'sabre',
  'satis',
  'sette',
  'slash',
  'tiger',
  'velar',
  'villa',
  'ville',
  'volyn',
  'zetec',
  'amaze',
  'brake',
  'cabin',
  'coach',
  'doors',
  'dupla',
  'salon',
  'style',
  'tramp',
  'vague',
  'vinyl',
  'works',
  'works',
  'front',
  'hydro',
  'lever',
  'lever',
  'multi',
  'robot',
  'shock',
  'solid',
  'steer',
  'strut',
  'strut',
  'wheel',
  ]
export default answers
